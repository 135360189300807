import logo from './assets/logo512.png';
import styles from './App.module.scss';
import roi_svg from './assets/ROI.svg';
import graph_svg from './assets/Graph.svg';
import artimis_jpg from './assets/artimis_device_for_web_444px.jpg';

function App() {
    return (
        <div className={styles.App}>
            <header className={styles['App-header']}>
                <img src={logo} className={styles['App-logo']} alt="logo" />
                <p className={styles.Title}>PhycoSight</p>
            </header>
            <div className={styles.Body}>
                {/* <p className={styles.BodyText1}>More information coming soon.</p> */}
                <p className={styles.Hero1}>Analyze Algae:</p>
                <div className={styles.SeparatorSmall}></div>
                <p className={styles.HeroDefault}>
                    <span className={styles.e1}><span className={styles.Hero2}>10X Faster</span> than manual microscopy</span>
                </p>
                <div className={styles.SeparatorSmall}></div>
                <div className={styles.ROIDiv}>
                    <div className={styles.GraphicDiv1}>
                        <img src={roi_svg} alt="Region of Interest Classification" />
                    </div>
                    <div className={styles.DescriptionDiv1}>
                        <p className={styles.DescriptionHeader1}>Automate Identification with PhycoSight AI</p>
                        <p className={styles.DescriptionText1}>PhycoSight is at the cutting edge of advanced computer vision and artificial intelligence techniques to quickly and accurately identify microorganisms, focusing specifically on traditionally hard-to-automate classification tasks.</p>
                    </div>
                </div>
                <div className={styles.SeparatorLarge}></div>
                <p className={styles.HeroDefault}>
                    <span className={styles.e2}><span className={styles.Hero2}>10X Higher Resolution</span> than fluorometric and TSS probes</span>
                </p>
                <div className={styles.SeparatorSmall}></div>
                <div className={styles.GraphDiv}>
                    <div className={styles.DescriptionDiv2}>
                        <p className={styles.DescriptionHeader2}>Construct Taxonomically-Resolved Time Series</p>
                        <p className={styles.DescriptionText2}>In addition to providing an accurate total biomass measurement, PhycoSight's data analysis software automates the creation of time series graphs to visualize trends in different algae subpopulations, to easily keep tabs on "good" or "bad" phytoplankton of interest.</p>
                    </div>
                    <div className={styles.GraphicDiv2}>
                        <img src={graph_svg} alt="Taxonomic Time Series" />
                    </div>
                </div>
                <div className={styles.SeparatorLarge}></div>
                <p className={styles.HeroDefault}>
                    <span className={styles.e3}><span className={styles.Hero2}>10X Less Expensive</span> than commercial imaging flow cytometers</span>
                </p>
                <div className={styles.SeparatorSmall}></div>
                <div className={styles.ImageDiv}>
                    <div className={styles.GraphicDiv3}>
                        <img src={artimis_jpg} alt="ARTIMIS Flow Imaging Microscope" className={styles.Image}/>
                    </div>
                    <div className={styles.DescriptionDiv3}>
                        <p className={styles.DescriptionHeader3}>Collect Microscopy Data with ARTIMIS</p>
                        <p className={styles.DescriptionText3}>ARTIMIS is a purpose-built, low-cost miniature flow imaging microscope, serving as an affordable platform that integrates PhycoSight AI for real-time data generation.</p>
                    </div>
                </div>
                <div className={styles.SeparatorLarge}></div>
                <p className={styles.BodyText2}><span className={styles.Hero2}>Sign Up</span> to receive more information and stay connected:</p>
                
            </div>
            <div className={styles.FormDiv}>
                <form 
                    name="contact_v1"
                    method="POST" 
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    className={styles.columnCenter}
                >
                    <input type="hidden" name="form-name" value="contact_v1"/>
                    <div hidden> 
                        <input name="bot-field" />
                    </div>
                    <div>
                        <label className={styles.FormLabel}>Name: 
                            <input className={styles.FormInput} type="text" name="name" />
                        </label>
                    </div>
                    <div>
                        <label className={styles.FormLabel}>Email: 
                            <input className={styles.FormInput} type="email" name="email" />
                        </label>
                    </div> <br />
                    <div className={styles.center}>
                        <button className={styles.FormButton} type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <div className={styles.Body}>
                <p className={styles.HeroDefault}>
                    <span className={styles.e4}><span className={styles.Hero2}>Stay Tuned</span> for updates on press, awards, and how you can get started with PhycoSight</span>
                </p>
            </div>
            <div className={styles.footer}></div>
        </div>
    );
}

export default App;
